import React from 'react';
import IndexLayout from '../layouts';
import { Helmet } from 'react-helmet';
import SiteNav from '../components/header/SiteNav';
import { Wrapper } from '../components/Wrapper';
import { Footer } from '../components/Footer';
import { css } from '@emotion/core';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull } from '../templates/post';
import { colors } from '../styles/colors';
import { StoryForm, IShareStoryForm } from '../components/share/StoryForm';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  .mb-1 {
    margin-bottom: 0.5rem;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const ShareStory: React.FC = () => {
  const submitStory = (formData: IShareStoryForm) => {
    alert("Your story has been submitted! We'll review it and post it if it meets our guidelines 🙂")
    /* Email
      .send({
        SecureToken: "45c5802c-f576-4ec2-8b77-cd20f55033dd",
        To: 'omrarecovery@gmail.com',
        From: "omrarecovery@gmail.com",
        Subject: "Claiming listing",
        Body: "clinic name: "+document.getElementById('clinic-name').value+'======clinic website: '+document.getElementById('clinic-website').value+'======submitter name: '+document.getElementById('submitter-name').value+'======submitter email: '+document.getElementById('submitter-email').value
      })
      .then((message) => {
          alert("Submitted! We'll be in touch soon, have a great rest of your day 🙂")
          console.log('message:', message)
          // Reset fields
          document.getElementById('clinic-name').value = '';
          document.getElementById('clinic-website').value = '';
          document.getElementById('submitter-name').value = '';
          document.getElementById('submitter-email').value = '';
      })
      .catch((error) => {
          console.log(error)
      }) */
    console.log('data to submit:', JSON.stringify(formData));
  };

  return (
    <IndexLayout>
      <Helmet>
        <title>Growth</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} />
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <article className="post page" css={[PostFull, NoImage]}>
              <h1 style={{ textAlign: 'center', paddingTop: '1.5rem' }}>Share your story</h1>

              <StoryForm submitStory={submitStory} />
            </article>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default ShareStory;
