import React, { useState } from 'react';
// import FormGroup from '@material-ui/core/FormGroup';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import { SubscribeFormButton, FormGroup, SubscribeFormStyles } from '../subscribe/SubscribeForm';
import { css } from '@emotion/core';

interface IProps {
  submitStory: (formData: IShareStoryForm) => void;
}

export interface IShareStoryForm {
  content: string;
  name?: string;
  email?: string;
  feedback?: string;
  firstName?: string;
  lastName?: string;
  age?: number;
  gender?: string;
  race?: string;
  location?: string;
}

const emptyForm = {
  content: '',
  // name: '',
  // email: '',
  // feedback: '',
  // firstName: '',
  // lastName: '',
  // age: 0,
  // gender: '',
  // race: '',
  // location: '',
};

const FormStyle = css`
  flex-direction: column;

  .MuiFormControl-root {
    margin-bottom: 0.5rem;
  }
  .MuiInputBase-input {
    font-size: 1.35rem;
  }
  .MuiFormLabel-root {
    font-size: 1.3rem;
  }
`;

export const StoryForm: React.FC<IProps> = ({ submitStory }) => {
  const [formData, setFormData] = useState<IShareStoryForm>(emptyForm);
  const [formErrors, setFormErrors] = useState<string | null>(null);

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setFormErrors('');

    // submit form data
    if (!formData.content) {
      alert("Hmmm, something isn't right. Check the page for errors")
      setFormErrors("Don't forget to submit a story!");
    } else if (formData.age! === 0) {
      setFormData({
        ...formData,
        age: undefined,
      });
    } else if (formData.age! < 0 || formData.age! > 120) {
      setFormErrors('please enter a valid age');
    } else {
      submitStory(formData);
      resetForm();
    }
  };

  const resetForm = () => {
    setFormData(emptyForm);
  };

  return (
    <form css={[SubscribeFormStyles, FormStyle]} onSubmit={handleSubmit}>
      {formErrors && (
        <p
          style={{
            color: 'rgba(255,0,0,.8)',
            textAlign: 'center',
            marginBottom: '.5rem',
            marginTop: '.5rem',
          }}
        >
          {formErrors.toString()}
        </p>
      )}
      <FormGroup
        className="form-group"
        style={{ display: 'block', textAlign: 'center', marginBottom: '1.5rem' }}
      >
        <p style={{ textAlign: 'left', display: 'inline-block', marginBottom: '1rem' }}>
          Whatever you're going through, whatever mistakes you've made, we care about you and value
          you.
        </p>
        <TextareaAutosize
          autoFocus
          aria-label="Share your story"
          rowsMin={8}
          placeholder="Share you story"
          name="content"
          style={{ width: '100%' }}
          value={formData.content}
          onChange={handleChange}
        />
        {/* <textarea
            required
            autoFocus
            placeholder="Share your story"
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 5,
              paddingBottom: 5,
              display: 'block',
              margin: 'auto',
            }}
            minLength={1}
            className="subscribe-email"
            name="content"
            value={formData.content}
            onChange={handleChange}
          /> */}
      </FormGroup>
      <p style={{ marginBottom: '.5rem' }}>
        <b>*NOTE: All of the following prompts are optional*</b>
        <br/>
        Provide as much or as little (even zero) information about yourself as you’d like.
        <br/>
        Remember that even if you choose to be completely anonymous, the story will be public, so
        only include details that you’re willing to be public.
      </p>
      <FormGroup className="form-group" style={{ marginBottom: '1.5rem' }}>
        <TextField
          fullWidth
          className="mb-3"
          type="text"
          name="firstName"
          label="First Name"
          size="medium"
          value={formData.firstName}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          className="mb-3"
          type="text"
          name="lastName"
          label="Last Name"
          // inputProps={{
          //   fontSize: 22,
          //   height: '1.3em',
          // }}
          value={formData.lastName}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          className="mb-3"
          type="number"
          name="age"
          label="Age"
          value={formData.age}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          className="mb-3"
          type="text"
          name="gender"
          label="Gender"
          value={formData.gender}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          className="mb-3"
          type="text"
          name="race"
          label="Race"
          value={formData.race}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          className="mb-3"
          type="text"
          name="location"
          label="Location"
          value={formData.location}
          onChange={handleChange}
        />
      </FormGroup>
      <p style={{ marginBottom: '1rem' }}>
        If you’d like to be notified if your story is posted, and when other stories are posted,
        enter your email here. It will never be made public.
      </p>
      <TextField
        fullWidth
        className="mb-3"
        style={{ marginBottom: '1.5rem' }}
        type="email"
        name="email"
        label="Email"
        placeholder="youremail@example.com"
        value={formData.email}
        onChange={handleChange}
      />

      <FormGroup className="form-group" style={{ marginBottom: '1.5rem' }}>
        <p>
          We’re always trying to make our community better. Feel free to tell us any ideas or feedback you have.
        </p>
        <TextareaAutosize
          aria-label="Share your feedback"
          rowsMin={4}
          name="feedback"
          placeholder="Share you feedback"
          style={{ width: '100%' }}
          value={formData.feedback}
          onChange={handleChange}
        />
      </FormGroup>

      <SubscribeFormButton type="submit">
        <span>Submit</span>
      </SubscribeFormButton>
    </form>
  );
};

// Old Code
// <FormGroup className="form-group" style={{ marginBottom: '1.5rem' }}>
// </FormGroup>

/* <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
    <input type="text" name={config.mailchimpName} tabIndex={-1} />
  </div> */
